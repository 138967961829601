import './App.css';
import { Route, Link } from 'react-router-dom'
import { Outlet, Routes } from 'react-router';
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInterval } from 'ahooks'
import classNames from 'classnames'
import useHotkeys from "@reecelucas/react-use-hotkeys";
import data from './data.json'
import './gif-player'

const dataMap = {}

data.forEach(json => {
  dataMap[json.author] = json
})

function Layout() {
  const authors = [{
    path: '/saint11',
    name: 'Saint11'
  }, {
    path: '/slynyrd',
    name: 'Slynyrd'
  }, {
    path: '/luke',
    name: 'Luke'
  }]

  const params = useParams()
  const navigate = useNavigate();

  return (
    <div className="App">
      <div className='header'>
        <div className="title" onClick={() => navigate('/')}><img src={`${process.env.PUBLIC_URL}/logo.gif`} width="32"/>PixelGif</div>
        <div className='nav'>
          {authors.map(author => {
            let className = ''
            if (params.author && author.name.toLowerCase() === params.author.toLowerCase()) {
              className = 'active'
            }
            return (
              <Link key={author.name} className={className} to={author.path}>
                {author.name}
              </Link>
            )
          })}
          <a target="_blank" rel="noreferrer" href="https://space.bilibili.com/326171860">支持作者</a>
        </div>
      </div>
      <div className="content">
        {params.author ? <Outlet /> : <Authors />}    
      </div>
    </div>
  )
}

function AuthorCard(props) {
  return (
    <div className={`author ${props.className}`}>
      <div className='cover'>
        <div className='avatar'></div>
      </div>
      <div className='name'>{props.name}</div>
      <div className='desc'>
        {props.children}
      </div>
    </div>
  )
}

function Authors() {
  return (
    <div className='authors'>
      <AuthorCard className="pedro" name="Saint11">
        <span>Brazilian game developer and co-founder of </span>
        <span>
          <a dir="ltr" href="https://exok.com/" role="link" target="_blank">@exok_games</a>
        </span>
        <span> and </span>
        <span>
          <a dir="ltr" href="https://www.studiominiboss.com/" role="link" target="_blank">@studioMiniBoss</a>
        </span>
        <span>. Out There Somewhere, TowerFall and </span>
        <span>
          <a dir="ltr" href="http://www.celestegame.com/" role="link" target="_blank">@celeste_game</a>
        </span>
        <span>. Working on Scars and Earthblade🌷</span>
      </AuthorCard>
      <AuthorCard className="slynyrd" name="Slynyrd">
        <span>
        Pixel artist, designer, educator. Co-creator of 
        <a dir="ltr" href="https://twitter.com/hyper_echelon" role="link" target="_blank">@hyper_echelon</a>
        <br/><br/>
        <span>
        <a dir="ltr" href="http://patreon.com/slynyrd" role="link" target="_blank">Support</a>
        </span>
        &nbsp;&nbsp;&nbsp;
        <span>
          <a dir="ltr" href="http://redbubble.com/people/Slynyrd" role="link" target="_blank">Shop</a>
        </span>

        </span>
      </AuthorCard>
      <AuthorCard className="luke" name="Luke">
        <span>
        Are you interested in animation or pixelart? Fancy a new hobby that is very calming? I have over 100 tutorials all FREE for YOU.
        </span>
        <br/>
        <span>
          <a dir="ltr" href="https://www.youtube.com/channel/UCueQD" role="link" target="_blank">Youtube</a>
        </span>
        &nbsp;&nbsp;&nbsp;
        <span>
          <a dir="ltr" href="http://discord.com/invite/vJ8VNpW" role="link" target="_blank">Discord</a>
        </span>
        &nbsp;&nbsp;&nbsp;
        <span>
          <a dir="ltr" href="
        http://ko-fi.com/sadface" role="link" target="_blank">Support</a>
        </span>
      </AuthorCard>
    </div>
  )
}

function Tutorial() {
  const { author, gif } = useParams();
  const isSaint11 = author === 'saint11'

  const renderItem = (item) => {
    const className = item.file === gif ? 'item active' : 'item'
    return (
      <Link key={item.path} className={className} to={item.path}>
        {isSaint11 ? item.name : `${item.index}. ${item.name}`}
      </Link>
    )
  }

  const renderItems = () => {
    if (isSaint11) {
      return (
        <>{dataMap[author].categories.map(cate => {
          return (
            <div key={cate.name}>
              <div  className='category-item'>{cate.name}</div>
              {cate.items.map(renderItem)}
            </div>
          )
        })}</>
      )
    } else {
      const items = dataMap[author].categories[0].items
      return (
        <>{items.map(renderItem)}</>
      )
    }
  }

  return (
    <div className='tutorial'>
      <div className='category'>
        {renderItems()}
      </div>  
      <div className='info'>
        <Outlet />
      </div>
    </div>
  )
}

function Gif() {
  const { author, gif } = useParams();
  const playerRef = useRef(null)
  const [gifLoaded, setGifLoaded] = useState(false)
  const [count, setCount] = useState(0)
  const [manualPause, setManualPause] = useState(false)
  const update = () => setCount(count + 1)
  const [frame, setFrame] = useState(0)
  const gifUrl = `${process.env.PUBLIC_URL}/tutorials/${author}/images/${gif}`

  const frames = useMemo(() => {
    if (!gifLoaded) {
      return []
    } else {
      let arr = []
      arr.length = playerRef.current._frames.length
      arr.fill(0)
      return arr
    }
  }, [gifLoaded])
  const displayBar = frames.length > 40

  useInterval(() => {
    let newVal = playerRef.current && playerRef.current.gifLoaded
    if (gifLoaded !== newVal) {
      setGifLoaded(newVal)
    }
    if (newVal) {
      if (playerRef.current.frame !== frame) {
        setFrame(playerRef.current.frame)
      }
    } else {
      setFrame(0)
    }
  }, 1000/60)

  const onMouseEnter = (e) => {
    if (manualPause) return
    playerRef.current.pausePlayback()
  } 
  const onMouseLeave = (e) => {
    if (manualPause) return
    playerRef.current.resumePlayback()
  }

  const setFrameByEvent = (e) => {
    if (displayBar) {
      let clientX = e.clientX

      var rect = e.currentTarget.querySelector('.bar').getBoundingClientRect()
      var x = clientX - rect.left;
      var position = x / rect.width;
      var curFrame = Math.round((playerRef.current._frames.length - 1) * position);
      playerRef.current.frame = curFrame
    } else {
      if (e.target.dataset.index === undefined) return 
      playerRef.current.frame = parseInt(e.target.dataset.index)
    }
  }
  const onMouseMove = (e) => {
    if (manualPause) return
    setFrameByEvent(e)
  }

  const onClickBar = (e) => {
    if (!manualPause) return
    setFrameByEvent(e)
  }

  const play = () => {
    playerRef.current.resumePlayback()
    setManualPause(false)
    update()
  }
  const pause = () => {
    playerRef.current.pausePlayback()
          setManualPause(true)
          update()
  }
  const prevFrame = () => {
    playerRef.current.pausePlayback()
    setManualPause(true)
    const frameNum = playerRef.current._frames.length
    let newFrame = frame - 1
    if (newFrame < 0) {
      newFrame = frameNum - 1
    }
    playerRef.current.frame = newFrame
  }
  const nextFrame = () => {
    playerRef.current.pausePlayback()
    setManualPause(true)
    const frameNum = playerRef.current._frames.length
    let newFrame = frame + 1
    if (newFrame >= frameNum) {
      newFrame = 0
    }
    playerRef.current.frame = newFrame
  }

  useHotkeys(" ", (e) => {
    e.stopPropagation()
    e.preventDefault()
    manualPause ? play() : pause()
  })
  useHotkeys("ArrowLeft", () => {
    prevFrame()
  })
  useHotkeys("ArrowRight", () => {
    nextFrame()
  })

  const renderControl = () => {
    if (!gifLoaded) return
    return (
      <div className="gif-control">
        {playerRef.current.paused && <span className="play" onClick={play} title="播放" style={{"--p": "0"}}></span>}
        {!playerRef.current.paused && <span className="pause" onClick={pause} title="暂停" style={{"--p": "-32px"}}></span>}
        <span className="prev" onClick={prevFrame} title="上一帧" style={{"--p": "-64px"}}></span>
        <span className="next" onClick={nextFrame} title="下一帧" style={{"--p": "-96px"}}></span>
        <a className='download' download={gif} target="_blank" href={gifUrl} title="下载 GIF" style={{"--p": "-128px"}}></a>
      </div>
    )
  }


  const renderFrames = () => {
    if (!playerRef.current || !playerRef.current.gifLoaded) {
      return
    }
    return (
      <div className="gif-frames" 
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onClick={onClickBar}
      >
        {displayBar && (
          <div className="bar" data-bar="true">
            <div className="cursor" style={{
              width: (512 / frames.length) + 'px',
              left: (frame / frames.length * 512) + 'px'
            }}></div>
          </div>
        )}
        {!displayBar && frames.map((_, idx) => {
          const className = classNames({
            'gif-frame': true,
            'active': frame === idx,
            'hoverable': manualPause,
          })
          return (
            <div 
              key={`frame-${idx}`} 
              data-index={idx}
              style={{
                cursor: manualPause ? 'pointer' : 'col-resize', 
              }}
              className={className} 
              onClick={() => {
                if (manualPause) {
                  playerRef.current.frame = idx
                }
              }}
            />
          )
        })}
      </div>
    )
  }

  const renderLoading = () => {
    if (gifLoaded) return
    return (
      <div className="loading" style={{ marginTop: 70,}}>
        <div><img src={`${process.env.PUBLIC_URL}/logo.gif`}/></div>
        <div>Loading...</div>
      </div>
    )
  }

  if (gif.endsWith('.gif')) {
    return (
      <div className='gif'>
        {renderControl()}
        {renderFrames()}
        <gif-player 
          ref={playerRef}
          key={gifUrl}
          src={gifUrl} 
          size="auto" 
          speed={1}
          play
          style={{ position: 'absolute', display: 'block', width: '512px', minHeight: '512px' }} />
        {renderLoading()}
    </div>
    )
  }

  return (
    <div className='gif'>
      <img src={gifUrl} alt="" />
    </div>
  )
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route path=":author" element={<Tutorial />}>
          <Route path=":gif" element={<Gif />}></Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
